<template>
  <div
    class="p-2 md:mt-0"
    :class="`${this.$store.state.eCommerce.showAlertHeader ? '' : 'mt-0'}`"
    style="margin-top: 63px"
  >
    <vs-breadcrumb
      class="block md:hidden"
      :items="breadcrumbItems"
      separator="chevron_right"
    >
    </vs-breadcrumb>
    <h1 class="pb-3">Promotions and New Arrivals</h1>
    <vs-breadcrumb
      class="hidden md:block mb-10"
      :items="breadcrumbItems"
      separator="chevron_right"
    >
    </vs-breadcrumb>

    <!-- dynamic  cards -->
    <div class="bg-opacity-100 w-full min-h-screen">
      <div
        v-for="items, index in JsonData.cards" :key="index"
        :class="`grid ${items.length > 1 ? 'grid-cols-2' : 'grid-cols-1'}`"
      >
        <div
            v-for="(card, cardIndex) in items"
            :key="cardIndex"
            class="p-6 flex flex-col border border-solid justify-center"
            style="border-color: #e5e7eb"
        >
          <img
            class="w-full hidden md:block"
            :src="card.desktop_image_url"
            alt="Promotion Image"
          />
          <img
            class="w-full md:hidden"
            :src="card.mobile_image_url"
            alt="Promotion Image"
          />
          <h3 class="pt-4">{{ card.title }}</h3>
          <div>{{ card.description }}</div>
          <div class="w-full h-full flex justify-center items-end mt-8">
            <vs-button @click="redirectToURL(card.link)" class="mb-3">
                {{ card.button_text }}
            </vs-button>
          </div>
        </div>
      </div>
    </div>

    <div v-if="productList.length > 0">
      <h3 class="py-5 pl-2">Products</h3>
      <shop-promotions-products :productList="productList" />
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import shopPromotionsProducts from "../ecommerce/shopPromotionsProducts.vue";
export default {
  components: { shopPromotionsProducts },
  data() {
    return {
      JsonData: {},
      clinicId: "",
      filterParams: {
        categoriesOptions: [],
        brandId: [],
        vendorOptions: [],
        sortBy: "order_asc",
        search: "",
        page: 1,
        limit: 12,
        clinicId: "",
      },
      productList: {},
    };
  },
  computed: {
    breadcrumbItems() {
      return [
        { title: "Home", url: "/"+this.$getUserType()+"/shop" },
        { title: "Promotions", active: true },
      ];
    },
  },
  methods: {
    ...mapActions("eCommerce", ["getJsonSettings", "productFilterListing"]),
    alertMargin() {
      var element = document.getElementsByClassName("router-content");
      if (element) {
        element[0].classList.add("shop-margin");
      }
    },
    async getJsonSettingsData() {
      await this.getJsonSettings({ slug: "promotions" })
        .then((val) => {
          this.JsonData = val.data.data;
          this.filterParams.categoriesOptions = [this.JsonData.products_slug];
          this.filterParams.clinicId = this.clinicId;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    async getProductsByCategory() {
      this.$vs.loading();
      try {
        const res = await this.productFilterListing(this.filterParams);
        const categoryExists = res.data.data.categories.length > 0;
        this.productList = categoryExists ? res.data.data.products : [];
        this.$vs.loading.close();
      } catch (error) {
        console.error(error);
        this.$vs.loading.close();
      }
      console.error(error);

      this.$vs.loading.close();
    },

    redirectToURL(url) {
      if (typeof window !== "undefined") {
        window.location.href = this.$replaceUrlTemplate(url, this.$getUserType());
      }
    },
  },
  watch: {
    "$store.state.clinicId": function (val) {
      this.clinicId = val;
      this.filterParams.clinicId = val;
      this.getProductsByCategory();
    },
  },
  async created() {
    this.clinicId = sessionStorage.getItem(`doctorClinicSelectedClinic`);
    await this.getJsonSettingsData();
    this.getProductsByCategory();
  },
};
</script>

<style lang="scss" scoped></style>
