<template>
  <div id="data-list-list-view" class="data-list-container px-5">
    <vs-button @click="getOwner()">{{
      hasOwner ? "Change Owner" : "Assign Owner"
    }}</vs-button>
    <vs-popup
      :title="hasOwner ? 'Change Owner' : 'Assign Owner'"
      :active.sync="popupActive"
    >
      <div>
        <vs-select
          placeholder="Select Owner"
          label="Names"
          autocomplete
          v-model="userId"
          class="w-full m-2"
        >
          <vs-select-item
            :key="index"
            :value="item._id"
            :text="`${item.fullName} (${item.email})`"
            v-for="(item, index) in users"
          />
        </vs-select>

        <vs-button
          @click="assignClinic()"
          color="primary"
          class="m-2"
          type="border"
          >Assign</vs-button
        >
      </div>
    </vs-popup>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "assign-owner",
  props: {
    // nurseId: {
    //   type: String,
    //   default: null,
    // },
    hasOwner: {
      type: Boolean,
      default: false,
    },
    // associatedIds: {
    //   type: Array,
    // },
  },
  data() {
    return {
      popupActive: false,
      users: [],
      userId: "",
      showSelect: true,
    };
  },
  methods: {
    ...mapActions("organization", ["fetchUsers", "assignOrganizationOwner"]),
    getOwner() {
      this.popupActive = true;
      this.getUsers(this.$route.params.organizationId);
    },
    async getUsers(id) {
      let self = this;
      this.fetchUsers({ id: id }).then((res) => {
        self.users = res.data.data;
        // self.totalDocs = res.data.data.pagination.total;
        // self.page = res.data.data.pagination.page;
      });
      this.serverResponded = true;
      //   },
    },
    async assignClinic() {
      let id = this.$route.params.organizationId;
      try {
        const res = await this.assignOrganizationOwner({
          id: id,
          data: { userId: this.userId },
        });
        this.$emit("onSuccess");
        this.$vs.notify({
          title: "Owner Assigned",
          text: "Organisation owner successfully assigned.",
          color: "success",
        });
        this.popupActive = false;
      } catch (err) {
        if (err.status === 422) {
          this.$vs.notify({
            title: "Failed",
            text: "Failed to assign owner. Try again!",
            color: "danger",
          });
        }
        if (err.status === 409) {
          this.$vs.notify({
            title: "Failed",
            text: err.data.message,
            color: "danger",
          });
          this.errors.add({
            field: "email",
            msg: err.data.message,
          });
        }
      }
    },
  },

  // async created() {
  //   this.getUsers(this.$route.params.organizationId);
  // },
};
</script>
